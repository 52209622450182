/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Components
import { SEO, ParseContent } from 'components/shared'
import Breadcrumb from 'components/elements/BreadCrumb'
import Layout from 'components/layout/Layout'
import Calculator from 'components/related/Calculator'
import Plaatjie from '@ubo/plaatjie'

const Hero = styled.section`
  margin-top: -10px;
  
  @media screen and (max-width: 992px){
    background-size: cover!important;
  }
`

const HeroContent = styled.div`
  padding-top: 155px;
  padding-bottom: 90px;
`

const PageTemplate = ({
  data: {
    page: { path, title, yoast_meta: yoast, acf },
  },
  pageContext
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} article />

      <Hero className="hero-image-small" style={{
          backgroundImage: `url(${acf.banner.background.localFile.childImageSharp.fluid.src})`
        }}>
        <HeroContent className="container px-3">
          <div className="mt-5 mb-3">
            <ParseContent content={`<h1><span style="color: rgb(255, 255, 255);"><strong>${title}</strong></span></h1>`} />
          </div>
          <Breadcrumb data={title} className="py-0" pageContext={pageContext} />
        </HeroContent>
      </Hero>

      <div className="container pt-5">
        <div className="px-lg-5">
          <ParseContent content={acf.meta.intro_description} />
        </div>
      </div>

      <div className="container pb-5 mb-5">
        <Calculator description={acf.module.description} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        meta {
          intro_description
        }

        banner {
          background {
            localFile {
              publicURL
              childImageSharp {
                fluid(toFormat: WEBP, maxWidth: 2000, quality:100) {
                  src
                }
              }
            }
          }
        }

        module {
          description
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate